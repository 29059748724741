import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import { DialogResponses } from '~/stores/useDialogStore';

export default function useUploadVerificationFileSuccessDialog() {
  const dialogStore = useDialogStore();
  return {
    open: async () => {
      return dialogStore.openDialog(
        DialogIdent.VERIFICATION_FILE_UPLOAD_SUCCESS,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data ?? DialogResponses.SUCCESS);
    },
    cancel: () => {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    },
  };
}
